import WysiwygController from "../wysiwyg_controller";

const styles = `
  body {
    font-family: 'Times New Roman';
    margin: 1rem auto;
  }

  p, li {
    text-align: justify;
  }

  .mceNonEditable {
    background-color: #D6F0FF;
    padding: 1px 0;
    color: #44719B;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 0.9375em;
  }
`;

export default class extends WysiwygController {
  connect() {
    super.connect();
  }

  get optionsValue() {
    return {
      content_style: styles,
    };
  }
}
